<div class="container">
    <h1 class="header">Question</h1>
    <p class="p-text">In this section you can create new questions, as well as manage the already existing
        questions displayed within the
        traveler interface of NIUMAD. Every question must have a unique identifier – Question key.

    <div class="row">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">
                                    <div fxLayout="row" class="mb-2">
                                        <mat-form-field fxFlex="100">
                                            <label>Search</label>
                                            <input matInput [formControl]="questionKeyFilter" class="textInput">
                                            <mat-hint>
                                                Search by entering a Question key (e.g. “traveler_gender” etc.) or just any keyword (e.g. “traveler”, “name” etc.).
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Notifications</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="registrationFilter" [matAutocomplete]="notify"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #notify="matAutocomplete">
                                                        <mat-option *ngFor="let option of notificationFilter | keyvalue"
                                                            [value]="option.key">
                                                            {{option.key}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Country</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="countryFilter" [matAutocomplete]="ctryFilter"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #ctryFilter="matAutocomplete">
                                                        <mat-option *ngFor="let country of filteredOptions | async"
                                                            [value]="country">
                                                            {{country}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>State</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="visibilityFilter" [matAutocomplete]="statusflt">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #statusflt="matAutocomplete">
                                                        <mat-option *ngFor="let status of statusFilter"
                                                            [value]="status">
                                                            {{status}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is profile</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isProfileFilter" [matAutocomplete]="isProfile"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #isProfile="matAutocomplete">
                                                        <mat-option *ngFor="let filter of profileFilter | keyvalue"
                                                            [value]="filter.key">
                                                            {{filter.key}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is static</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isStaticFilter" [matAutocomplete]="isStatic"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #isStatic="matAutocomplete">
                                                        <mat-option *ngFor="let option of staticFilter | keyvalue"
                                                            [value]="option.key">
                                                            {{option.key}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is encrypted</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="isEncryptedFilter" [matAutocomplete]="encrypt"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #encrypt="matAutocomplete">
                                                        <mat-option *ngFor="let option of encryptedFilter | keyvalue"
                                                            [value]="option.key">
                                                            {{option.key}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-5 main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 class="table-subheader">Available Questions</h2>
                </div>
                <div>
                    <div style="padding:10px;cursor:pointer" (click)="createQuestion()">
                        <img src="assets/images/add-icon.svg" alt="Add Icon" length="25" width="25" class="ml-1">
                        <span class="btn-text pl-2">Create a Question</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="allQuestions" matSort 
                            class="mat-elevation-z1" matSortActive="id" matSortDirection="asc">

                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="key">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Key </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.question}} </td>
                            </ng-container>

                            <ng-container matColumnDef="registations">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Registrations </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngFor="let reg of element.registations">
                                        {{ reg.name }}
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Type </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.type | titlecase }}
                                    <ng-container *ngIf="element.tags.length !== 0">
                                        <mat-chip-list>
                                            <mat-chip class="matchip-box">{{element.tags[0].name}}</mat-chip>
                                        </mat-chip-list>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dependency">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Dependency </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.parent !== null ? element.parent.id :
                                    'No'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Status </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.visibility | titlecase }} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace"> Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <button type="button" mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button type="button" mat-menu-item [matMenuTriggerFor]="enable">
                                            <span>Status</span>
                                        </button>
                                        <button type="button" mat-menu-item (click)="translateQuestion(element.id)">
                                            <span>Translate</span>
                                        </button>
                                        <button type="button" mat-menu-item routerLinkActive="is-active"
                                            [routerLink]="['/questions/questions/edit-question', element.id]">
                                            <span>Edit</span>
                                        </button>
                                        <button type="button" mat-menu-item (click)="removeQuestion(element.id)">
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>

                                    <mat-menu #enable="matMenu">
                                        <button type="button" [disabled]="element.visibility === 'enabled' ? true : false"
                                            mat-menu-item (click)="enableStatus(element.id, element)">
                                            <span>Enable</span>
                                        </button>
                                        <button type="button" [disabled]="element.visibility === 'disabled' ? true : false"
                                            mat-menu-item (click)="disableStatus(element.id, element)">
                                            <span>Disable</span>
                                        </button>
                                    </mat-menu>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="assessmentrow"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="footer-btn">
    <div class="row bottom-button">
        <div class="buttom-right">
            <button type="button" mat-raised-button class="btnsave backbtn" (click)="clearFilter($event)">
                Reset
            </button>
            <button mat-raised-button type="submit" class="btnsave submitbtn">
                Filter
            </button>
        </div>
    </div>
</div>