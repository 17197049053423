import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { QuestionOptionService } from '../../../services/question-option.service';
import { QuestionService } from '../../../services/question.service';

@Component({
  selector: 'app-update-parent',
  templateUrl: './update-parent.component.html',
  styleUrls: ['./update-parent.component.scss']
})
export class UpdateParentComponent implements OnInit {

  listParentData: MatTableDataSource<any> = null;
  questionID: any;
  questionParentData: any = [];

  parentColumn: string[] = ['id', 'name', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private questionOption: QuestionOptionService,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UpdateParentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    console.log(this.route.snapshot.params['id']);

    console.log('Edit option data', this.data);
    
    this.questionService.newQuestionData.subscribe((res) => {
      console.log('Get question data into update parent comp', res);
      this.questionID = res.parent.id;
    });

    this.questionOption.getOptionByQuestionId(this.questionID).subscribe((res) => {
      console.log('List of parent data', res.data);
      this.listParentData = new MatTableDataSource<any>(res.data);
      this.listParentData.paginator = this.paginator;
      this.listParentData.sort = this.sort;
    });

  }

  addParent(id: number) {
    this.questionParentData.push({
      "ids": [
        {
          "id": id
        }
      ]
    });
    this.questionOption.updateOptionParent(this.data.id, this.questionParentData[0]).subscribe((res) => {
      console.log('updated data', res);
      this.openSnackbarSuccessMessage('Parent added successfully');
    });
  }

  removeParent(id: number) {
    this.questionParentData.push({
      "ids": [
        {
          "id": id
        }
      ]
    });
    this.questionOption.removeOptionParent(this.data.id, this.questionParentData[0]).subscribe((res) => {
      console.log('updated data', res);
      this.openSnackbarSuccessMessage('Parent removed successfully');
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
