import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { QuestionService } from 'src/app/modules/questions/services/question.service';

export interface IMenu {
  text: string;
  routerLink?: string;
  children: IMenuItem[];
}
export interface IMenuItem {
  text: string;
  routerLink: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isExpanded = true;
  showSubmenu: boolean = false;
  showLangMenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showLogo: boolean = false;
  menuList: Observable<IMenu[]>;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private questionService: QuestionService) { }

  ngOnInit(): void {
    this.menuList = this.questionService.getList<IMenu>("assets/menu.json");
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

}
