import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CountriesService } from '../../services/countries.service';
import { TranslateCountriesComponent } from '../translate-countries/translate-countries.component';

export interface Countries {
  id: number;
  code: string;
  name: string;
  is_Home: boolean;
  is_Host: boolean;
  is_A1: boolean;
  is_PWD: boolean;
}

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
})
export class CountriesComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = ['One', 'Two', 'Three'];
  countryFilter: string[] = ['Not a home', 'Is home'];
  servicesFilter: string[] = ['Is enabled', 'Is diabled'];
  displayedColumns: string[] = ['id', 'name', 'code', 'actions'];
  allCountries: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private countriesService: CountriesService
  ) {}

  ngOnInit() {
    this.allCountries = [];
    this.getCountries();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  getCountries() {
    try {
      this.countriesService.getAllCountries().subscribe((res) => {
        this.allCountries = new MatTableDataSource<any>(res.data);
        this.allCountries.paginator = this.paginator;
        this.allCountries.sort = this.sort;
      });
    } catch (ex) {}
  }

  translateQuestion(countryID) {
    const dialogRef = this.dialog.open(TranslateCountriesComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: countryID
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Home country API integration
   * @param code 
   * @param data 
   */

  enableHomeCountry(code: any, data: any) {
    this.countriesService.enableHomeCountry(code, data).subscribe((res) => {
      console.log('enable home country', res);
      this.getCountries();
    });
  }

  disableHomecountry(code: any, data: any) {
    this.countriesService.disableHomeCountry(code, data).subscribe((res) => {
      console.log('disable home country', res);
      this.getCountries();
    });
  }

  /**
   * Host country API intergration
   * @param code 
   * @param data 
   */

  enableHostCountry(code: any, data: any) {
    this.countriesService.enableHostCountry(code, data).subscribe((res) => {
      console.log('enable host country', res);
      this.getCountries();
    });
  }

  disableHostcountry(code: any, data: any) {
    this.countriesService.disableHostCountry(code, data).subscribe((res) => {
      console.log('disable host country', res);
      this.getCountries();
    });
  }

  /**
   * A1 service API integration
   * @param code 
   * @param data 
   */

  enableA1Service(code: any, data: any) {
    this.countriesService.enableA1Service(code, data).subscribe((res) => {
      console.log('enable host country', res);
      this.getCountries();
    });
  }

  disableA1Service(code: any, data: any) {
    this.countriesService.disableA1Service(code, data).subscribe((res) => {
      console.log('disable host country', res);
      this.getCountries();
    });
  }

  /**
   * PWD service API intergration
   * @param code 
   * @param data 
   */

  enablePwdService(code: any, data: any) {
    this.countriesService.enablePwdService(code, data).subscribe((res) => {
      console.log('enable host country', res);
      this.getCountries();
    });
  }

  disablePwdService(code: any, data: any) {
    this.countriesService.disablePwdService(code, data).subscribe((res) => {
      console.log('disable host country', res);
      this.getCountries();
    });
  }

  // TODO: Country translate

}
