import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { QuestionService } from '../../services/question.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {

  displayedColumns: string[] = ['id', 'question', 'order', 'actions'];
  availableQuestions: MatTableDataSource<any> = null;
  matcher = new MyErrorStateMatcher();

  groupFrom: FormGroup;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  availableQuestionsRowData: any = [];
  options: string[] = ['purpose', 'detail'];
  id: number;
  name = '';
  type = '';

  showSaveIcon: boolean = false;
  showEditIcon: boolean = true;

  VOForm: FormGroup;
  newOrderID: any = [];
  groupID: number;
  editedOrderID: any;
  newTableValue: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;


  constructor(private route: ActivatedRoute, private fb: FormBuilder,
    private questionService: QuestionService, private router: Router,
    private fbuilder: FormBuilder,
    private _formBuilder: FormBuilder,
    private questionGroupService: QuestionGroupsService,
    private snackBar: MatSnackBar) {
    this.VOForm = this.fbuilder.group({
      VORows: this._formBuilder.array([])
    });
  }

  ngOnInit() {



    this.groupID = this.route.snapshot.params['id'];

    this.getQuestionGroup(this.route.snapshot.params['id']);

    this.getQuestionOrder();

    this.questionService.getQuestionsByGroupId(this.groupID).subscribe((res) => {
      console.log('question group by order id', res.data);

      this.VOForm = this.fbuilder.group({
        VORows: this.fbuilder.array(res.data.map(val => this.fb.group({
          id: new FormControl(val.id),
          question: new FormControl(val.question),
          order: new FormControl(val.order),
          isEditable: new FormControl(true),
        })
        ))
      });

      this.availableQuestions = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
      this.availableQuestions.paginator = this.paginator;
      this.availableQuestions.sort = this.sort;
    });

    this.groupFrom = this.fb.group({
      'name': ['', Validators.required],
      'type': ['', Validators.required]
    });

    this.filteredOptions = this.groupFrom.get('type').valueChanges.pipe(
      startWith(''),
      map(value => this.filterType(value))
    );
  }

  filterType(value: string): string[] {
    if(value !== null) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  getQuestionOrder() {
    try {
      this.questionService.getQuestions().subscribe((res: any) => {

        console.log('Get all question', res.data);
        res.data.forEach((e) => {
          if (e.group.id === parseInt(this.route.snapshot.params['id'])) {
            this.availableQuestionsRowData.push({
              id: e.id,
              question: e.question,
              order: e.order
            });
          }
        });
        console.log('availableQuestionsRowData', this.availableQuestionsRowData);
      });

    } catch (ex) { }
  }

  getQuestionGroup(id: any) {
    this.questionGroupService.getQuestionGroupsById(id).subscribe((res: any) => {
      this.id = res.data.id;
      this.groupFrom.setValue({
        'name': res.data.name,
        'type': res.data.type
      });
    });
  }

  EditSVO(VOFormElement: any, i: any) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
  }

  get orderID() {
    return this.VOForm.get('VORows') as FormArray;
  }

  getOrderValue(value: any) {
    this.editedOrderID = value;
  }

  SaveVO(VOFormElement: any, i: any, questionId: any) {

    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);

    this.newOrderID.push({
      order: Number(this.editedOrderID)
    });
    this.questionService.updateQuestionByOrder(this.groupID, questionId, this.newOrderID[0]).subscribe((res) => {
      console.log('Order updated data', res);
      this.openSnackbarSuccessMessage('Order updated successfully');
      this.refresh();
    });

  }

  refresh(): void {
    window.location.reload();
  }

  onGroupSubmit() {
    this.questionGroupService.updateQuestionGroups(this.id, this.groupFrom.value)
      .subscribe((res: any) => {
        this.openSnackbarSuccessMessage('Group updated successfully');
        this.router.navigate(['/questions/question-group']); // TODO: Check which path needs to redirect
      }, (err: any) => {
        console.log(err);
      }
      );
  }

  exitGroup() {
    this.router.navigate(['/questions/question-group']); // TODO: Check which path needs to redirect
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
