<div class="container">
    <h1 class="header">Languages</h1>
    <p class="p-text">
        This section contains a list of languages available within NIUMAD. A new language can be created or an existing
        language can be deleted here. In case of creation of a new language, an option to translate the individual
        questions, countries and traveler NIUMAD interface placeholders will be added into the whole ADMIN NIUMAD
        interface.
    </p>
    <div class="row main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 class="table-subheader">Available Languages</h2>
                </div>
                <div>
                    <div style="padding:10px;cursor:pointer" (click)="createLanguage()">
                        <img src="assets/images/add-icon.svg" alt="Add Icon" length="25" width="25" class="ml-1">
                        <span class="btn-text pl-2">Create language</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <form [formGroup]="tableGroupFrom">
                            <table mat-table [dataSource]="allLanguages" matSort 
                                class="mat-elevation-z1" matSortActive="id" matSortDirection="asc">

                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span class="headletterspace"> # </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span class="headletterspace">Code </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span class="headletterspace">Name </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="country">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span class="headletterspace">Country </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.country.name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="headletterspace"> Action</span>
                                    </th>

                                    <td mat-cell *matCellDef="let element">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button type="button" mat-menu-item [routerLink]="['/languages/edit-language', element.id]" routerLinkActive="is-active">
                                                <span>Edit</span>
                                            </button>
                                            <button type="button" mat-menu-item [disabled]="element.is_Default === true" (click)="removeLanguage(element.code)">
                                                <span>Delete</span>
                                            </button>
                                        </mat-menu>

                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="assessmentrow">
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>