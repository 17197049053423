<div class="container">
    <h1 class="header">Question group edit</h1>
    <div class="row mb-5">
        <div class="col-md-12">
            <form [formGroup]="groupFrom" (ngSubmit)="onGroupSubmit()">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Group parameters</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Name</label>
                                            <input matInput formControlName="name" class="textInput"
                                                [errorStateMatcher]="matcher">
                                            <mat-error>
                                                <span
                                                    *ngIf="!groupFrom.get('name').valid && groupFrom.get('name').touched">Name
                                                    is <strong>required</strong></span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Group</label>
                                            <input type="text" formControlName="type" class="textInput"
                                                aria-label="Group" matInput [formControl]="myControl"
                                                [matAutocomplete]="auto" #trigger="matAutocompleteTrigger"
                                                [errorStateMatcher]="matcher">
                                            <mat-icon
                                                (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                class="custom-icon custom-form-field-icon">
                                                expand_more
                                            </mat-icon>
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                <mat-option *ngFor="let option of filteredOptions | async"
                                                    [value]="option">
                                                    {{option}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error>
                                                <span
                                                    *ngIf="!groupFrom.get('type').valid && groupFrom.get('type').touched">Group
                                                    is <strong>required</strong></span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end">
                                <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
                                    Cancel
                                </button>
                                <button mat-raised-button class="btnsave submitbtn" [disabled]="!groupFrom.valid">
                                    Save
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row filter-card">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Search</label>
                                                    <input matInput class="textInput">
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Notifications</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Country</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>State</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is profile</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is static</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is dcoument</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Is encrypted</label>
                                                    <input type="text" class="textInput" aria-label="Group" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end">
                                <button type="button" mat-raised-button class="btnsave backbtn">
                                    Reset
                                </button>
                                <button mat-raised-button type="submit" class="btnsave submitbtn">
                                    Filter
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row main-table">
        <div class="col-md-12">
            <h2 class="sub-header">Available Group</h2>

            <form [formGroup]="VOForm" autocomplete="off">
                <ng-container formArrayName="VORows">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="sub-table table-responsive">
                                <table mat-table [dataSource]="availableQuestions" matSort class="mat-elevation-z1">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <span class="headletterspace"> # </span>
                                        </th>
                                        <td mat-cell *matCellDef="let element let i = index" [formGroup]="element">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" formControlName="id" [readonly]="true">
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="question">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <span class="headletterspace">Question </span>
                                        </th>
                                        <td mat-cell *matCellDef="let element let i = index" [formGroup]="element">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" formControlName="question" [readonly]="true">
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <span class="headletterspace">Order</span>
                                        </th>

                                        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                                            <mat-form-field
                                                [appearance]="VOForm.get('VORows').value[i].isEditable? 'none' : 'legacy'">
                                                <input #orderNo (keyup)="getOrderValue(orderNo.value)" matInput
                                                    type="text" formControlName="order">
                                            </mat-form-field>
                                        </td>

                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <span class="headletterspace">Actions</span>
                                        </th>

                                        <td mat-cell *matCellDef="let element;  let i = index" [formGroup]="element">

                                            <button type="button" mat-raised-button class="btnsave submitbtn"
                                                matTooltip="Save Changes"
                                                (click)="SaveVO(VOForm, i, VOForm.get('VORows').value[i].id)"
                                                *ngIf="!VOForm.get('VORows').value[i].isEditable">
                                                Save
                                            </button>

                                            <button type="button" mat-raised-button class="btnsave backbtn"
                                                *ngIf="VOForm.get('VORows').value[i].isEditable" matTooltip="Edit"
                                                (click)="EditSVO(VOForm,i)">
                                                Edit
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="assessmentrow">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>

                </ng-container>

            </form>
        </div>
    </div>

</div>