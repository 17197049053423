import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { environment } from 'src/environments/environment';
import { AllLanguages } from '../models/allLanguages';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  // Languages

  getLanguages(): Observable<AllLanguages> {
    return this.http.get<AllLanguages>(`${this.env.API_URL}/api/v1/Language`);
  }

  getLanguageByCode(languageCode: string): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Language/${languageCode}`);
  }

  getLanguageList(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Language/LanguagesList`);
  }

  getDefaultLanguage(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Language/DefaultLanguage`);
  }

  createLanguage(data: any): Observable<any> {
    return this.http.post<any>(`${this.env.API_URL}/api/v1/Language`, data);
  }

  updateLanguage(languageCode: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Language/${languageCode}`, data);
  }

  updateDefaultLanguage(languageCode: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.env.API_URL}/api/v1/Language/${languageCode}/default`, data);
  }

  removeLanguage(languageCode: string): Observable<any> {
    return this.http.delete<any>(`${this.env.API_URL}/api/v1/Language/${languageCode}`);
  }

  // Translations

  getTranslations(): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Translations`);
  }

  getTranslationByCode(languageCode: string): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Translations/${languageCode}`);
  }

  updateTranslationByCode(languageCode: string, data: any): Observable<any> {
    return this.http.get<any>(`${this.env.API_URL}/api/v1/Translations/${languageCode}`, data);
  }

}
