<div class="container">
    <h1 class="header">Countries</h1>
    <p class="p-text">
        This section contains a list of all the countries enabled in the PWD Assessment.
        If the country is disabled, the traveler will not be able to create a trip to such country.
    </p>
    <div class="row">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Search</label>
                                            <input matInput class="textInput">
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Home</label>
                                                    <input type="text" class="textInput" aria-label="Home" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>A1</label>
                                                    <input type="text" class="textInput" aria-label="A1" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Host</label>
                                                    <input type="text" class="textInput" aria-label="Host" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>PWD</label>
                                                    <input type="text" class="textInput" aria-label="PWD" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-5 main-table">
        <div class="col-md-12">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="table-mainheader" fxLayout.xs="column"
                fxLayoutGap="1rem">
                <div>
                    <h2 class="table-subheader">Available Countries</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="allCountries" matSort class="mat-elevation-z1" matSortActive="id"
                            matSortDirection="asc">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Name </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Code </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="headletterspace"> Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="translateQuestion(element.id)">
                                            <span>Translate</span>
                                        </button>
                                        <button mat-menu-item [matMenuTriggerFor]="home">
                                            <span>Home</span>
                                        </button>
                                        <button mat-menu-item [matMenuTriggerFor]="host">
                                            <span>Host</span>
                                        </button>
                                        <button mat-menu-item [matMenuTriggerFor]="a1" [disabled]="element.is_Home === false">
                                            <span>A1</span>
                                        </button>
                                        <button mat-menu-item [matMenuTriggerFor]="pwd" [disabled]="element.is_Host === false">
                                            <span>PWD</span>
                                        </button>
                                    </mat-menu>

                                    <mat-menu #home="matMenu">
                                        <button mat-menu-item [disabled]="element.is_Home === true"
                                            (click)="enableHomeCountry(element.code, element)">
                                            <span>Enable</span>
                                        </button>
                                        <button mat-menu-item [disabled]="element.is_Home === false"
                                            (click)="disableHomecountry(element.code, element)">
                                            <span>Disable</span>
                                        </button>
                                    </mat-menu>

                                    <mat-menu #host="matMenu">
                                        <button mat-menu-item [disabled]="element.is_Host === true"
                                            (click)="enableHostCountry(element.code, element)">
                                            <span>Enable</span>
                                        </button>
                                        <button mat-menu-item [disabled]="element.is_Host === false"
                                            (click)="disableHostcountry(element.code, element)">
                                            <span>Disable</span>
                                        </button>
                                    </mat-menu>

                                    <mat-menu #a1="matMenu">
                                        <button mat-menu-item [disabled]="element.is_A1 === true"
                                            (click)="enableA1Service(element.code, element)">
                                            <span>Enable</span>
                                        </button>
                                        <button mat-menu-item [disabled]="element.is_A1 === false"
                                            (click)="disableA1Service(element.code, element)">
                                            <span>Disable</span>
                                        </button>
                                    </mat-menu>

                                    <mat-menu #pwd="matMenu">
                                        <button mat-menu-item [disabled]="element.is_PWD === true"
                                            (click)="enablePwdService(element.code, element)">
                                            <span>Enable</span>
                                        </button>
                                        <button mat-menu-item [disabled]="element.is_PWD === false"
                                            (click)="disablePwdService(element.code, element)">
                                            <span>Disable</span>
                                        </button>
                                    </mat-menu>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="assessmentrow"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="footer-btn">
    <div class="row bottom-button">
        <div class="buttom-right">
            <button mat-raised-button class="btnsave backbtn">
                Reset
            </button>
            <button mat-raised-button type="submit" class="btnsave submitbtn">
                Filter
            </button>
        </div>
    </div>
</div>