<div class="container">
    <h1 class="header">Document types</h1>
    <div class="row mb-5">
        <div class="col-md-12">
            <form [formGroup]="docTypeForm" (ngSubmit)="onDocumentTypeSubmit()">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Document type paramaters</h2>
                        <mat-card>
                            <div class="row main-card">
                                <div class="col-md-12">

                                    <!-- Key -->
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Key</label>
                                            <input matInput formControlName="documentTypeText" class="textInput"
                                                [errorStateMatcher]="matcher">
                                            <mat-error>
                                                <span
                                                    *ngIf="!docTypeForm.get('documentTypeText').valid && docTypeForm.get('documentTypeText').touched">Key
                                                    is <strong>required</strong></span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- Name -->
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Name</label>
                                            <input matInput formControlName="documentTypeName" class="textInput"
                                                [errorStateMatcher]="matcher">
                                            <mat-error>
                                                <span
                                                    *ngIf="!docTypeForm.get('documentTypeName').valid && docTypeForm.get('documentTypeName').touched">Name
                                                    is <strong>required</strong></span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- Service -->
                                    <div fxLayout="row">
                                        <mat-form-field fxFlex="100">
                                            <label>Services</label>
                                            <mat-select formControlName="services" multiple>
    
                                                <mat-select-trigger>
                                                    <mat-chip-list>
                                                        <mat-chip *ngFor="let service of services.value" [removable]="true"
                                                            (removed)="removeRegistraion(service)">
                                                            {{ service }}
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </mat-chip-list>
                                                </mat-select-trigger>
    
                                                <mat-option *ngFor="let service of registrationData" [value]="service.name">
                                                    {{service.name}}
                                                </mat-option>
    
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Trips addition -->
                                    <div fxLayout="row" class="mt-4">
                                        <label>Trips addition</label>
                                        <mat-radio-group formControlName="tripsAddition" aria-label="Select an option">
                                            <mat-radio-button value="manual" class="ml-4 mr-4">Manual</mat-radio-button>
                                            <mat-radio-button value="auto">Auto</mat-radio-button>
                                        </mat-radio-group>
                                    </div>

                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="end">
                                <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
                                    Cancel
                                </button>
                                <button mat-raised-button class="btnsave submitbtn"
                                    [disabled]="!docTypeForm.valid">
                                    Submit
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="sub-header">Filter</h2>
                        <mat-card>
                            <div class="row filter-card">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Search</label>
                                                    <input matInput class="textInput">
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <mat-form-field fxFlex="100">
                                                    <label>Country</label>
                                                    <input type="text" class="textInput" aria-label="country" matInput
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        #trigger="matAutocompleteTrigger">
                                                    <mat-icon
                                                        (click)="trigger.panelOpen ? trigger.closePanel() : trigger.openPanel()"
                                                        class="custom-icon custom-form-field-icon">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let country of countriesData"
                                                            [value]="country.name">
                                                            {{country.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end" class="filter-card">
                                <button type="button" mat-raised-button class="btnsave backbtn">
                                    Reset
                                </button>
                                <button mat-raised-button type="submit" class="btnsave submitbtn">
                                    Filter
                                </button>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row main-table">
        <div class="col-md-12">
            <h2 class="sub-header">Available Group</h2>

            <div class="row">
                <div class="col-md-12">
                    <div class="sub-table table-responsive">
                        <table mat-table [dataSource]="availableQuestions" matSort 
                            class="mat-elevation-z1" matSortActive="id" matSortDirection="asc">

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace"> # </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="question">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Question </span>
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.question}} </td>
                            </ng-container>

                            <ng-container matColumnDef="order">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Order</span>
                                </th>

                                <td mat-cell *matCellDef="let element; let i = index">
                                    <ng-container *ngIf="showEditIcon">
                                        {{element.order}}
                                    </ng-container>
                                    <ng-container *ngIf="showSaveIcon">
                                        <mat-form-field style="width: 70px;">
                                            <input matInput type="text">
                                        </mat-form-field>
                                    </ng-container>
                                </td>

                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <span class="headletterspace">Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element; let i = index">
                                    <button type="button" *ngIf="showSaveIcon" mat-raised-button class="btnsave submitbtn"
                                        matTooltip="Save Changes" aria-label="Question order updated with index"
                                        (click)="saveQuestionOrder()">
                                        Save
                                    </button>
                                    <button type="button" *ngIf="showEditIcon" mat-raised-button class="btnsave backbtn"
                                        matTooltip="Edit" (click)="editQuestionOrder()">
                                        Edit
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="assessmentrow"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>