import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotFound404Component } from './components/not-found404/not-found404.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { OrgSelectComponent } from './dialogs/org-select/org-select.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';


@NgModule({
  declarations: [
    HeaderComponent,
    NavbarComponent,
    NotFound404Component,
    OrgSelectComponent,
    ActivityLogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    HeaderComponent,
    NavbarComponent
  ]
})
export class CoreModule { }
