import { Component, OnInit } from '@angular/core';
import { QuestionService } from 'src/app/modules/questions/services/question.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

  questionLogData: any;

  constructor(private questionService: QuestionService) { }

  ngOnInit(): void {

    this.questionLogData = [];

    this.questionService.getQuestions().subscribe((res) => {

      res.data.forEach((e) => {
        this.questionService.getActivityLog(e.id).subscribe((res) => {
          
          res.data.forEach((log) => {
            this.questionLogData.push({
              action: log.action,
              action_By: log.action_By,
              action_Date: log.action_Date,
              action_Date_To_Display: log.action_Date_To_Display,
              attribute: log.attribute,
              entity: log.entity,
              previous_Value: log.previous_Value,
              subEntity: log.subEntity,
              updated_Value: log.subEntity
            });
          });
        });
      });
    });
    console.log('Activity log', this.questionLogData);
  }

}
