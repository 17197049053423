import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionService } from 'src/app/modules/questions/services/question.service';
import { DocumentsService } from '../../services/documents.service';
import { MyErrorStateMatcher } from '../create-document-type/create-document-type.component';

@Component({
  selector: 'app-edit-document-type',
  templateUrl: './edit-document-type.component.html',
  styleUrls: ['./edit-document-type.component.scss']
})
export class EditDocumentTypeComponent implements OnInit {

  displayedColumns: string[] = ['id', 'question', 'order', 'actions'];
  availableQuestions: MatTableDataSource<any> = null;
  matcher = new MyErrorStateMatcher();

  docTypeForm: FormGroup;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  availableQuestionsRowData: any = [];
  newDocumentTypeData: any = [];
  options: string[] = ['purpose', 'detail'];
  id = '';
  name = '';
  type = '';
  documentTypeID: number;
  countriesData: any = [];

  showSaveIcon: boolean = false;
  showEditIcon: boolean = true;

  registrationData: any[] = [];
  newRegistrationData: any[] = [];
  servicesByID: any = [];
  listServiceData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, private fb: FormBuilder,
    private documentsService: DocumentsService, 
    private questionService: QuestionService,
    private countriesService: CountriesService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.listServiceData = [];

    this.documentTypeID = this.route.snapshot.params['id'];

    this.getDocumentType(this.route.snapshot.params['id']);

    this.getQuestionOrder();

    this.docTypeForm = this.fb.group({
      'documentTypeText': ['', Validators.required],
      'documentTypeName': ['', Validators.required],
      'services': [''],
      'tripsAddition': ['manual']
    });

    this.countriesService.getAllCountries().subscribe((res) => {
      console.log('get all countries', res.data);
      this.countriesData = res.data;
    });

    // Registrations api
    this.questionService.getRegistrations().subscribe((res) => {
      this.registrationData = res.data;
      console.log('service list ', this.registrationData);
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  getQuestionOrder() {
    try {
      this.questionService.getQuestions().subscribe((res: any) => {

        console.log('Get all question', res.data);
        res.data.forEach((e) => {
          if (e.group.id === parseInt(this.route.snapshot.params['id'])) {
            this.availableQuestionsRowData.push({
              id: e.id,
              question: e.question,
              order: e.order
            });
          }
        });
        this.availableQuestions = new MatTableDataSource<any>(this.availableQuestionsRowData);
        this.availableQuestions.paginator = this.paginator;
        this.availableQuestions.sort = this.sort;
      });
    } catch (ex) { }
  }

  get services(): FormControl {
    return this.docTypeForm.get('services') as FormControl;
  }

  removeRegistraion(service: string) {
    const registrations = this.services.value as string[];
    this.removeFirst(registrations, service);
    this.services.setValue(registrations); // To trigger change detection
  }

  removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  getDocumentType(id: any) {
    this.documentsService.getDocumentTypeByID(id).subscribe((res: any) => {
      console.log('Get document type by id', res.data);
      this.id = res.data.id;

      if (res.data.services.length !== 0) {
        this.listServiceData.push(res.data.services);
      }

      this.docTypeForm.setValue({
        'documentTypeText': res.data.documentTypeText,
        'documentTypeName': res.data.documentTypeName,
        'services': res.data.services.length === 0 ? null : this.listServiceData,
        'tripsAddition': res.data.tripsAddition
      });
    });
  }

  onDocumentTypeSubmit() {

    this.newDocumentTypeData = [];
    this.servicesByID = [];

    let regArray = this.docTypeForm.value.services;
    console.log('registraion array', regArray);

    if(regArray !== null) {
      regArray.forEach((e) => {
        this.registrationData.forEach((data) => {
          if(e === data.name) {
            this.servicesByID.push(data.id);
          }
        });
      });
    }

    this.newDocumentTypeData.push({
      documentTypeText: this.docTypeForm.value.documentTypeText,
      documentTypeName: this.docTypeForm.value.documentTypeName,
      services: this.servicesByID,
      tripsAddition: this.docTypeForm.value.tripsAddition
    });
    this.documentsService.updateDocumentType(this.id, this.newDocumentTypeData[0])
      .subscribe((res: any) => {
        console.log('Updated document type data', this.documentTypeID, res);
        this.openSnackbarSuccessMessage('Document updated successfully');
        this.router.navigate(['documents/documents-types']); // TODO: Check which path needs to redirect
      }, (err: any) => {
        console.log(err);
      }
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  exitGroup() {
    this.router.navigate(['/questions/question-group']); // TODO: Check which path needs to redirect
  }

  editQuestionOrder() {
    this.showEditIcon = false;
    this.showSaveIcon = true;
  }

  saveQuestionOrder() {
    this.showEditIcon = true;
    this.showSaveIcon = false;
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
