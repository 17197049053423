import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Question } from '../../models/question';
import { QuestionGroups } from '../../models/questionGroups';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { QuestionService } from '../../services/question.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss'],
})
export class CreateQuestionComponent implements OnInit {

  questionForm: FormGroup;
  filteredOptions: Observable<any[]>;
  filteredType: Observable<any[]>;
  filteredVisibility: Observable<any[]>;
  options: any = [];
  matcher = new MyErrorStateMatcher();
  questionType = {
    'Text question': 'text',
    'Number question': 'number',
    'Yes/No question': 'bool',
    'Choice question': 'choice',
    'Date time question': 'datetime',
    'Country': 'country',
    'Company': 'company',
  };
  visibility: string[] = ['Enabled', 'Disabled'];
  groupDropdownData: QuestionGroups[] = [];
  newQuestionData: any = [];

  constructor(
    private fb: FormBuilder,
    private questionService: QuestionService,
    private questionGroupService: QuestionGroupsService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {

    this.groupDropdownData = [];
    this.options = [];

    this.questionForm = this.fb.group({
      group: ['', Validators.required],
      type: ['', Validators.required],
      text: ['', Validators.required],
      visibility: ['', Validators.required],
    });

    try {
      this.questionGroupService.getQuestionGroups().subscribe((res: any) => {
        console.log('Get question group', res.data);
        this.groupDropdownData.push(res.data);
        this.options.push(this.groupDropdownData[0]);

        this.filteredOptions = this.questionForm.get('group').valueChanges.pipe(
          startWith(''),
          map((term) => {
            return this.options[0].filter((option: any) => option.name.toLowerCase().includes(term.toString().toLowerCase()));
          })
        );

      });
    } catch (ex) {}

    this.filteredType = this.questionForm.get('type').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return Object.keys(this.questionType).filter((type: any) => type.toLowerCase().includes(term.toString().toLowerCase()))
      })
    );

    this.filteredVisibility = this.questionForm.get('visibility').valueChanges.pipe(
      startWith(''),
      map((term) => {
        return this.visibility.filter((visibile: any) => visibile.toLowerCase().includes(term.toString().toLowerCase()))
      })
    );
  }

  displayGroupName(group: any) {
    return group ? group.name : '';
  }

  get questionGroup() {
    return this.questionForm.controls;
  }

  questionTypeConvert() {
    let newtype =  Object.entries(this.questionType).filter((key) => key.includes(this.questionGroup.type.value))
    return newtype[0][1];
  } 

  onSubmit() {
    this.newQuestionData.push({
      group: this.questionGroup.group.value.id,
      type: this.questionTypeConvert(),
      text: this.questionGroup.text.value,
      visibility: this.questionGroup.visibility.value.toLowerCase(),
      parent: null,
      parent_Rule: null,
      parent_Type: null,
      required: false,
      help: null,
      placeholder: null,
      validation: null,
      validation_help: null,
      company_Type: null,
      order: 0,
      is_Prefilled: false,
      is_Profile: false,
      is_Document: null,
      document_Type_Id: null,
      is_Static: false,
      default: null,
      is_Encrypted: false,
      profile_Type: null,
      min_Length: null,
      max_Length: null,
      min_Value: null,
      max_Value: null,
      multi_Choice: null,
      variant: null,
      tags: null,
      is_Purpose: null,
      max_Iterations: null
    });

    console.log('New question data', this.newQuestionData[0]);

    try {
      this.questionService.createQuestion(this.newQuestionData[0]).subscribe(
        (res: any) => {
          console.log('Created question', res);
          this.openSnackbarSuccessMessage('Question created successfully');

          let questionID = res.data.id;
          this.router.navigate(['/questions/questions/edit-question', questionID]);
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (ex) {}
  }

  exitQuestion() {
    this.router.navigate(['/questions/questions']);
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
