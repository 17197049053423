import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IdleTimeoutService } from 'src/app/shared/services/idle-timeout.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public activityId: number;
  public employeeId: number;
  public ordId: number;
  public orgName: string;
  public role: string;
  public accessSubject = new Subject();


  constructor(private idle: IdleTimeoutService) { }

  initAppConfig(data) {
    this.initAppSettings(data);
    this.idle.watch();
  }

  initAppSettings(settings: any) {
    console.log('settings', settings);

    this.activityId = settings.employeeOrganizationActivityId;
    this.employeeId = settings.employeeId;
    this.ordId = settings.organizationId;
    this.orgName = settings.organizationName;
    this.role = settings.appRoleName;
  }
}
