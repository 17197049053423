import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { QuestionService } from 'src/app/modules/questions/services/question.service';
import { DocumentsService } from '../../services/documents.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface DcoumentType {
  documentTypeText: string;
  documentTypeName: string;
  services: Services[];
  tripsAddition: string;
}

export interface Services {
  id: number;
  name: string;
  isEnabled: boolean;
}

@Component({
  selector: 'app-create-document-type',
  templateUrl: './create-document-type.component.html',
  styleUrls: ['./create-document-type.component.scss']
})
export class CreateDocumentTypeComponent implements OnInit {

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = ['purpose', 'detail'];
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  registrationData: any[] = [];
  newRegistrationData: DcoumentType[] = [];
  servicesByID: any = [];


  constructor(private fb: FormBuilder, private documentsService: DocumentsService,
    private snackBar: MatSnackBar, private router: Router, private questionService: QuestionService) {

    // Registrations api
    this.questionService.getRegistrations().subscribe((res) => {
      this.registrationData = res.data;
      console.log('topping list ', this.registrationData);
    });

  }

  ngOnInit() {

    this.myForm = this.fb.group({
      'documentTypeText': ['', Validators.required],
      'documentTypeName': ['', Validators.required],
      'services': [null, Validators.required],
      'tripsAddition': ['manual']
    });
  }

  get services(): FormControl {
    return this.myForm.get('services') as FormControl;
  }

  removeRegistraion(service: string) {
    const registrations = this.services.value as string[];
    this.removeFirst(registrations, service);
    this.services.setValue(registrations); // To trigger change detection
  }

  removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  get documentTypeData() {
    return this.myForm.controls;
  }

  onSubmit() {

    this.newRegistrationData = [];
    this.servicesByID = [];

    console.log('form data', this.documentTypeData);
    console.log('registraion data', this.registrationData);


    let regArray = this.documentTypeData.services.value;
    console.log('registraion array', regArray);

    if(regArray !== null) {
      regArray.forEach((e) => {
        this.registrationData.forEach((data) => {
          if(e === data.name) {
            this.servicesByID.push({
              id: data.id,
              name: data.name,
              isEnabled: data.isEnabled
            });
          }
        });
      });
    }

    console.log('new services ids ', this.servicesByID);


    this.newRegistrationData.push({
      documentTypeText: this.documentTypeData.documentTypeText.value,
      documentTypeName: this.documentTypeData.documentTypeName.value,
      services: this.servicesByID,
      tripsAddition: this.documentTypeData.tripsAddition.value
    });

    console.log('new registration data', this.newRegistrationData[0]);

    try {
      this.documentsService.createDocumentType(this.newRegistrationData[0]).subscribe((res) => {
        console.log('Dcoument created data', res);
        this.openSnackbarSuccessMessage('Document created successfully');
        this.router.navigate(['/documents/documents-types']);
      },
        (err) => { console.log(err); },
      );
    } catch (ex) { }
  }

  exitGroup() {
    this.router.navigate(['/documents/documents-types']);
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
