import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/core/services/app.service';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionService } from '../../services/question.service';
import { TranslateQuestionComponent } from '../translate-question/translate-question.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredCountries: Observable<string[]>;
  notification: string[] = ['A1', 'PWD', 'EE', 'PE'];
  options: string[] = ['One', 'Two', 'Three'];
  displayedColumns: string[] = [
    'position',
    'key',
    'registations',
    'type',
    'dependency',
    'status',
    'actions',
  ];
  allQuestions: MatTableDataSource<any> = null;
  tagName: any;

  profileFilter = { 'is_Profile': true };
  staticFilter = { 'is_Static': true };
  encryptedFilter = { 'is_Encrypted': true };
  notificationFilter = { 'A1': 'A1', 'PWD': 'PWD' };
  statusFilter = ['enabled', 'disabled'];
  allCountries: any = [];

  questionKeyFilter = new FormControl('');
  isProfileFilter = new FormControl('');
  isStaticFilter = new FormControl('');
  isEncryptedFilter = new FormControl('');
  registrationFilter = new FormControl('');
  countryFilter = new FormControl('');
  visibilityFilter = new FormControl('');


  filterValues = {
    question: '',
    is_Profile: '',
    is_Static: '',
    is_Encrypted: '',
    registations: '',
    // country: '',
    visibility: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private countriesService: CountriesService,
    private questionService: QuestionService,
    private snackBar: MatSnackBar,
    private app: AppService
  ) { }

  ngOnInit() {
    this.allCountries = [];

    this.getQuestions();

    this.app.showLoader = true;
    this.countriesService.getAllCountries().subscribe((res) => {
      this.app.showLoader = false;
      console.log('All countries', res);
      this.allCountries = res.data;
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.filteredCountries = this.countryFilter.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCountries(value))
    );

    this.isProfileFilter.valueChanges.subscribe(
      (profile) => {
        console.log('profile', profile);
        if (profile !== null) {
          let profileType = Object.entries(this.profileFilter).filter((key) => key.includes(profile));

          this.filterValues.is_Profile = profileType[0][1] === true ? profileType[0][1].toString() : '';
          this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.isStaticFilter.valueChanges.subscribe(
      (is_Static) => {
        if (is_Static !== null) {
          let staticType = Object.entries(this.staticFilter).filter((key) => key.includes(is_Static));
          this.filterValues.is_Static = staticType[0][1] === true ? staticType[0][1].toString() : '';
          this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.isEncryptedFilter.valueChanges.subscribe(
      (encrypt) => {
        if (encrypt !== null) {
          let encryptType = Object.entries(this.encryptedFilter).filter((key) => key.includes(encrypt));
          this.filterValues.is_Encrypted = encryptType[0][1] === true ? encryptType[0][1].toString() : '';
          this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

    this.questionKeyFilter.valueChanges
      .subscribe(
        question => {
          if (question !== null) {
            this.filterValues.question = question;
            this.allQuestions.filter = JSON.stringify(this.filterValues);
          }
        }
      );

    this.registrationFilter.valueChanges.subscribe(
      (service) => {
        if (service !== null) {
          this.filterValues.registations = service;
          this.allQuestions.filter = JSON.stringify(this.filterValues);
        }
      });

      // this.countryFilter.valueChanges
      // .subscribe(
      //   country => {
      //     if (country !== null) {
      //       this.filterValues.country = country.toLowerCase;
      //       this.allQuestions.filter = JSON.stringify(this.filterValues);
      //     }
      //   }
      // );

      this.visibilityFilter.valueChanges
      .subscribe(
        visibile => {
          if (visibile !== null) {
            this.filterValues.visibility = visibile.toLowerCase();
            this.allQuestions.filter = JSON.stringify(this.filterValues);
          }
        }
      );
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCountries.filter((option) =>
      option.name.toString().toLowerCase().includes(filterValue)
    );
  }

  getQuestions() {
    try {
      this.app.showLoader = true;
      this.questionService.getQuestions().subscribe((res: any) => {
        this.app.showLoader = false;
        console.log('Get all questions', res.data);

        res.data.forEach((e) => {
          if (e.tags.length !== 0) {
            this.tagName = e.tags[0].name;
          }
        });

        this.allQuestions = new MatTableDataSource<any>(res.data);
        this.allQuestions.paginator = this.paginator;
        this.allQuestions.sort = this.sort;

        this.allQuestions.filterPredicate = this.createFilter();

      });
    } catch (ex) { }

  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let regFound = data.registations.filter((e) => {
        return (e.name === searchTerms.registations)
      }).length > 0;

      return data.question.toLowerCase().indexOf(searchTerms.question) !== -1
        && String(data.is_Profile).includes(searchTerms.is_Profile)
        && String(data.is_Static).includes(searchTerms.is_Static)
        && String(data.is_Encrypted).includes(searchTerms.is_Encrypted)
        && regFound
        && data.visibility.includes(searchTerms.visibility);
        // && data.country.toLowerCase().includes(searchTerms.country)
    }
    return filterFunction;
  }

  clearFilter(event) {
    this.questionKeyFilter.reset();
    this.isProfileFilter.reset();
    this.isStaticFilter.reset();
    this.isEncryptedFilter.reset();
    this.registrationFilter.reset();
    // this.countryFilter.reset();
    this.visibilityFilter.reset();
    this.allQuestions.filter = '';
    event.stopPropagation();
  }

  createQuestion() {
    this.router.navigate(['create-question'], { relativeTo: this.route });
  }

  editQuestion() {
    this.router.navigate(['edit-question'], { relativeTo: this.route });
  }

  translateQuestion(id) {
    const dialogRef = this.dialog.open(TranslateQuestionComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: id
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  removeQuestion(id: number) {
    this.app.showLoader = true;
    this.questionService.removeQuestion(id).subscribe((res) => {
      this.app.showLoader = false;
      this.openSnackbarSuccessMessage('Question removed successfully');
      this.getQuestions();
    });
  }

  enableStatus(id: number, data: any) {
    if (data.visibility === 'disabled') {
      let updatedStatus = Object.assign({
        "visibility": "enabled"
      });
      this.app.showLoader = true;
      this.questionService.updateQuestionStatus(id, updatedStatus).subscribe((res) => {
        this.app.showLoader = false;
        console.log('Is status enabled', res);
        this.openSnackbarSuccessMessage('Status updated successfully');
        this.getQuestions();
      });
    }
  }

  disableStatus(id: number, data: any) {
    if (data.visibility === 'enabled') {
      let updatedStatus = Object.assign({
        "visibility": "disabled"
      });
      this.app.showLoader = true;
      this.questionService.updateQuestionStatus(id, updatedStatus).subscribe((res) => {
        this.app.showLoader = false;
        console.log('Is status disabled', res);
        this.openSnackbarSuccessMessage('Status updated successfully');
        this.getQuestions();
      });
    }
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
