import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ActivityLogComponent } from '../activity-log/activity-log.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  showLogo: boolean = false;

  constructor(private shared: SharedService, private dialog: MatDialog) { }

  home(event) {
    window.location.href = this.shared.homeUrl;
    event.stopPropagation();
  }

  activityLog() {
    console.log('Activity Log');

    const dialogRef = this.dialog.open(ActivityLogComponent, {
      width: '750px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  logout() {
    console.log("Logged Out!!");
    localStorage.clear();
    window.location.href = this.shared.logoutUrl;
  }

}
