<div class="row container mb-3">
  <div class="col-md-12">
    <h1 class="header">Edit question</h1>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <form [formGroup]="editQuestionForm" (ngSubmit)="onEditQuestionSubmit()">
      <div class="row group-params mb-4">
        <div class="col-md-12">
          <!-- Question basic info -->
          <div class="row mb-5">
            <div class="col-md-12">
              <h2 class="sub-header">Question basic info</h2>
              <mat-card>
                <div class="row main-card">
                  <div class="col-md-12">

                    <!-- Group-->
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100">
                        <label>Group</label>
                        <input type="text" formControlName="group" class="textInput" aria-label="Group" matInput
                          [matAutocomplete]="group" #trigger="matAutocompleteTrigger" [errorStateMatcher]="matcher" />
                        <mat-icon (click)="
                            trigger.panelOpen
                              ? trigger.closePanel()
                              : trigger.openPanel()
                          " class="custom-icon custom-form-field-icon">
                          expand_more
                        </mat-icon>
                        <mat-autocomplete autoActiveFirstOption #group="matAutocomplete" [displayWith]="groupDisplay">
                          <mat-option *ngFor="let group of filteredGroup | async" [value]="group">
                            {{ group.name }}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error>
                          <span *ngIf="
                              !editQuestionForm.get('group').valid &&
                              editQuestionForm.get('group').touched
                            ">Group is <strong>required</strong></span>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Question key -->
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100">
                        <label>Question key</label>
                        <input matInput formControlName="question" class="textInput" [readonly]="isReadOnly" [errorStateMatcher]="matcher" />
                        <mat-error>
                          <span *ngIf="
                              !editQuestionForm.get('question').valid &&
                              editQuestionForm.get('question').touched
                            ">Question Key is <strong>required</strong></span>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Visibility -->
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100">
                        <label>Visibility</label>
                        <input type="text" formControlName="visibility" class="textInput" aria-label="Group" matInput 
                          [matAutocomplete]="visibile" #trigger="matAutocompleteTrigger" />
                        <mat-icon (click)="
                            trigger.panelOpen
                              ? trigger.closePanel()
                              : trigger.openPanel()
                          " class="custom-icon custom-form-field-icon">
                          expand_more
                        </mat-icon>
                        <mat-autocomplete autoActiveFirstOption #visibile="matAutocomplete">
                          <mat-option *ngFor="let option of filteredVisibility | async" [value]="option">
                            {{ option }}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error>
                          <span *ngIf="
                              !editQuestionForm.get('visibility').valid &&
                              editQuestionForm.get('visibility').touched
                            ">Visibility is <strong>required</strong></span>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <!-- Maximum iterations -->
                    <div *ngIf="isBoolType || isChoiceType">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Maximum iterations</label>
                          <input matInput formControlName="max_Iterations" class="textInput" />
                          <mat-error *ngIf="editQuestionForm.get('max_Iterations').hasError('min')">
                            <span>Please enter a number greater than <strong>Zero</strong></span>
                          </mat-error>
                          <mat-error *ngIf="editQuestionForm.get('max_Iterations').hasError('pattern')">
                            <span>Please enter a <strong>numeric</strong> value</span>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <!-- Rules -->
          <div class="row mb-5">
            <div class="col-md-12">
              <h2 class="sub-header">Rules</h2>
              <mat-card>
                <div class="main-card">
                  <div class="col-md-12">

                    <!-- Required -->
                    <div fxLayout="row">
                      <mat-checkbox formControlName="required" class="mt-3 mb-3" fxFlex="100" [disabled]="isReadOnly">
                        Is required
                      </mat-checkbox>
                    </div>

                    <!-- Profile -->
                    <div fxLayout="row">
                      <mat-checkbox formControlName="is_Profile" #isProfileChecked class="mt-3 mb-3" fxFlex="100">
                        Is profile
                      </mat-checkbox>
                    </div>
                    <div fxLayout="row" *ngIf="isProfileChecked.checked">
                      <mat-form-field fxFlex="100">
                        <label>Answer obtained</label>
                        <input type="text" matInput formControlName="profile_Type" class="textInput"
                          [matAutocomplete]="visibile" #trigger="matAutocompleteTrigger" />
                        <mat-icon (click)="
                            trigger.panelOpen
                              ? trigger.closePanel()
                              : trigger.openPanel()
                          " class="custom-icon custom-form-field-icon">
                          expand_more
                        </mat-icon>
                        <mat-autocomplete #visibile="matAutocomplete">
                          <mat-option *ngFor="let answer of filteredAnswers | async" [value]="answer">
                            {{ answer }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>

                    <!-- Prefilled -->
                    <div fxLayout="row">
                      <mat-checkbox formControlName="is_Prefilled" class="mt-3 mb-3" fxFlex="100">
                        Prefilled
                      </mat-checkbox>
                    </div>

                    <!-- Static -->
                    <div *ngIf="isTextType || isBoolType || isNumberType || isChoiceType || isDatetimeType">
                      <div fxLayout="row">
                        <mat-checkbox formControlName="is_Static" #isStaticChecked [disabled]="isReadOnly" class="mt-3 mb-3" fxFlex="100">
                          Is static
                        </mat-checkbox>
                      </div>
                      <div fxLayout="row" *ngIf="isStaticChecked.checked">
                        <mat-form-field fxFlex="100">
                          <label>Default value</label>
                          <input matInput formControlName="default" class="textInput" />
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Encrypted -->
                    <div *ngIf="isTextType || isNumberType">
                      <div fxLayout="row">
                        <mat-checkbox formControlName="is_Encrypted" class="mt-3 mb-3" fxFlex="100">
                          Is encrypted
                        </mat-checkbox>
                      </div>
                    </div>

                    <!-- Multi choice -->
                    <div *ngIf="isChoiceType">
                      <!-- <div formGroupName="choice"> -->
                      <div fxLayout="row">
                        <mat-checkbox formControlName="multi_Choice" [disabled]="isReadOnly" class="mt-3 mb-3" fxFlex="100">
                          Multi choice
                        </mat-checkbox>
                      </div>
                      <!-- </div> -->
                    </div>

                    <!-- Document Type -->
                    <div *ngIf="isTextType || isDatetimeType || isCountryType">
                      <div fxLayout="row">
                        <mat-checkbox formControlName="is_Document" #isDocumentChecked class="mt-3 mb-3" fxFlex="100">Is
                          document?</mat-checkbox>
                      </div>
                      <div fxLayout="row" *ngIf="isDocumentChecked.checked">
                        <mat-form-field fxFlex="100">
                          <label>Document type</label>
                          <input type="text" formControlName="document_Type_Id" class="textInput" aria-label="Group"
                            matInput [matAutocomplete]="docType" #trigger="matAutocompleteTrigger" />
                          <mat-icon (click)="
                              trigger.panelOpen
                                ? trigger.closePanel()
                                : trigger.openPanel()
                            " class="custom-icon custom-form-field-icon">
                            expand_more
                          </mat-icon>
                          <mat-autocomplete autoActiveFirstOption #docType="matAutocomplete"
                            [displayWith]="doctypeNameDisplay">
                            <mat-option *ngFor="let docType of filteredDocType | async" [value]="docType">
                              {{ docType.documentTypeName }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Min length and Max length -->
                    <div *ngIf="isTextType">
                      <!-- <div formGroupName="text"> -->
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Minimal length</label>
                          <input matInput formControlName="min_Length" class="textInput" [readonly]="isReadOnly" />
                          <mat-error *ngIf="editQuestionForm.get('min_Length').hasError('pattern')">
                            <span>Please enter a <strong>numeric</strong> value</span>
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Maximal length</label>
                          <input matInput formControlName="max_Length" class="textInput" [readonly]="isReadOnly" />
                          <mat-error *ngIf="editQuestionForm.get('max_Length').hasError('pattern')">
                            <span>Please enter a <strong>numeric</strong> value</span>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- </div> -->
                    </div>

                    <!-- Min value and Max value-->
                    <div *ngIf="isNumberType">
                      <!-- <div formGroupName="number"> -->
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Minimal value</label>
                          <input matInput formControlName="min_Value" class="textInput" />
                          <mat-error *ngIf="editQuestionForm.get('min_Value').hasError('pattern')">
                            <span>Please enter a <strong>numeric</strong> value</span>
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Maximal value</label>
                          <input matInput formControlName="max_Value" class="textInput" />
                          <mat-error *ngIf="editQuestionForm.get('max_Value').hasError('pattern')">
                            <span>Please enter a <strong>numeric</strong> value</span>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- </div> -->
                    </div>

                    <!-- Validation -->
                    <div *ngIf="isTextType || isBoolType || isNumberType || isDatetimeType">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Validation rule [regular expression]</label>
                          <input matInput formControlName="validation" [(ngModel)]="validationInput" class="textInput" />
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Validation help -->
                    <ng-container
                      *ngIf="editQuestionForm.get('validation').valid && validationInput !== null && validationInput !== ''">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Validation help</label>
                          <input matInput formControlName="validation_help" class="textInput" />
                        </mat-form-field>
                      </div>
                    </ng-container>

                    <!-- Datetime -->
                    <div *ngIf="isDatetimeType">
                      <!-- <div formGroupName="dateTime"> -->
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Datetime variant</label>
                          <input type="text" class="textInput" formControlName="variant" matInput
                            [matAutocomplete]="visibile" #trigger="matAutocompleteTrigger" [readonly]="isReadOnly" />
                          <mat-icon (click)="
                                trigger.panelOpen
                                  ? trigger.closePanel()
                                  : trigger.openPanel()
                              " class="custom-icon custom-form-field-icon">
                            expand_more
                          </mat-icon>
                          <mat-autocomplete autoActiveFirstOption #visibile="matAutocomplete">
                            <mat-option *ngFor="let option of filteredDatetime | async" [value]="option">
                              {{ option }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <!-- </div> -->
                    </div>

                    <!-- Tooltip -->
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100">
                        <label>Help tooltip</label>
                        <input matInput formControlName="help" class="textInput" />
                      </mat-form-field>
                    </div>

                    <!-- Placeholder -->
                    <div *ngIf="!isBoolType">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Placeholder</label>
                          <input matInput formControlName="placeholder" class="textInput" [readonly]="isReadOnly" />
                          <mat-error *ngIf="editQuestionForm.get('placeholder').hasError('max_length')">
                            <span>Please enter a characters less than <strong>500</strong> </span>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Show for companies -->
                    <div *ngIf="isDatetimeType || isCompanyType">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100">
                          <label>Show for companies</label>
                          <mat-select formControlName="company_Type" multiple>

                            <mat-select-trigger>
                              <mat-chip-list>
                                <mat-chip *ngFor="let company of company_Type.value" [removable]="true"
                                  (removed)="removeCompanyType(company)">
                                  {{company}}
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                              </mat-chip-list>
                            </mat-select-trigger>

                            <mat-option *ngFor="let company of companiesData" [value]="company">
                              {{company}}
                            </mat-option>

                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Tags -->
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100">
                        <label>Tags</label>
                        <mat-select formControlName="tags" multiple [disabled]="isReadOnly">

                          <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip *ngFor="let tag of tags.value" [removable]="true" [readonly]="isReadOnly"
                                (removed)="removeRegistraion(tag)">
                                {{ tag }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-select-trigger>

                          <mat-option *ngFor="let tag of tagsData" [value]="tag">
                            {{tag}}
                          </mat-option>

                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <!-- A1 and PWD service cards-->
          <div class="row mb-5">
            <div class="col-md-12">
              <div *ngFor="let registration of registrationData">
                <h2 class="sub-header"> {{ registration.name }}</h2>

                <div *ngIf="registration.id === 1">
                  <mat-card class="p-0 mb-5">
                    <div class="row">
                      <div class="col-md-6">
                        <mat-card class="p-3">
                          <mat-card-title>
                            <h3 class="pt-1 pb-1">Show for home countries</h3>
                          </mat-card-title>
                          <mat-card-subtitle class="pt-3 pb-3">
                            <div class="row" fxLayoutAlign="start center">
                              <div class="col-md-6">
                                <mat-slide-toggle [checked]="isToggleChecked" [readonly]="isReadOnly"
                                  (change)="addAlAllCountries(registration.id, $event)">Add all countries
                                </mat-slide-toggle>
                              </div>
                              <div class="col-md-6">
                                <div style="cursor: pointer" (click)="addA1HomeCountries()" [disabled]="isReadOnly">
                                  <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25"
                                    class="ml-1" />
                                  <span class="btn-text pl-2">Add Countries</span>
                                </div>
                              </div>
                            </div>
                          </mat-card-subtitle>
                          <mat-card-content>

                            <div *ngIf="!isToggleChecked">
                              <div *ngIf="alHomeCountriesData.length !== 0; else noCountries">
                                <div class="row">
                                  <div class="col-md-12">
                                    <table mat-table [dataSource]="alHomeCountriesData" matSort class="mat-elevation-z0"
                                      matSortActive="id" matSortDirection="asc">
                                      <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef>
                                          <span class="headletterspace"> ID </span>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                          {{ element.id }}
                                        </td>
                                      </ng-container>

                                      <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>
                                          <span class="headletterspace">Name</span>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                          {{ element.name }}
                                        </td>
                                      </ng-container>

                                      <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef>
                                          <span class="headletterspace">Code</span>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                          {{ element.code }}
                                        </td>
                                      </ng-container>

                                      <tr mat-header-row *matHeaderRowDef="questionCountryColumn; sticky: true"></tr>
                                      <tr mat-row *matRowDef="let row; columns: questionCountryColumn"
                                        class="assessmentrow">
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <ng-template #noCountries>
                                <p class="text-center p-3">No countries</p>
                              </ng-template>
                            </div>
                            <div *ngIf="isToggleChecked">
                              <p class="text-center p-3">All countries</p>
                            </div>

                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <mat-card class="p-3">
                            <mat-card-title>
                              <h3 class="pt-1 pb-1">Show for host countries</h3>
                            </mat-card-title>
                            <mat-card-subtitle class="pt-3 pb-3">
                              <div class="row" fxLayoutAlign="start center">
                                <div class="col-md-6">
                                  <mat-slide-toggle [readonly]="isReadOnly">Add all countries</mat-slide-toggle>
                                </div>
                                <div class="col-md-6">
                                  <div style="cursor: pointer" [disabled]="isReadOnly">
                                    <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25"
                                      class="ml-1" />
                                    <span class="btn-text pl-2">Add Countries</span>
                                  </div>
                                </div>
                              </div>
                            </mat-card-subtitle>
                            <mat-card-content>
                              <p class="text-center p-3">No countries</p>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>

                <div *ngIf="registration.id === 2">
                  <mat-card class="p-0 mb-3">
                    <div class="row">
                      <div class="col-md-6">
                        <mat-card class="p-3">
                          <mat-card-title>
                            <h3 class="pt-1 pb-1">Show for home countries</h3>
                          </mat-card-title>
                          <mat-card-subtitle class="pt-3 pb-3">
                            <div class="row" fxLayoutAlign="start center">
                              <div class="col-md-6">
                                <mat-slide-toggle [readonly]="isReadOnly">Add all countries</mat-slide-toggle>
                              </div>
                              <div class="col-md-6">
                                <div style="cursor: pointer" [disabled]="isReadOnly">
                                  <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25"
                                    class="ml-1" />
                                  <span class="btn-text pl-2">Add Countries</span>
                                </div>
                              </div>
                            </div>
                          </mat-card-subtitle>
                          <mat-card-content>
                            <p class="text-center p-3">No countries</p>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <mat-card class="p-3">
                            <mat-card-title>
                              <h3 class="pt-1 pb-1">Show for host countries</h3>
                            </mat-card-title>
                            <mat-card-subtitle class="pt-3 pb-3">
                              <div class="row" fxLayoutAlign="start center">
                                <div class="col-md-6">
                                  <mat-slide-toggle (change)="addPwdAllCountries(registration.id)">Add all countries
                                  </mat-slide-toggle>
                                </div>
                                <div class="col-md-6" (click)="addPwdHostCountries()">
                                  <div style="cursor: pointer">
                                    <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25"
                                      class="ml-1" />
                                    <span class="btn-text pl-2">Add Countries</span>
                                  </div>
                                </div>
                              </div>
                            </mat-card-subtitle>
                            <mat-card-content>

                              <div *ngIf="!isToggleChecked">
                                <div *ngIf="pwdHostCountriesData.length !== 0 ; else noCountries">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <table mat-table [dataSource]="pwdHostCountriesData" matSort
                                        class="mat-elevation-z0" matSortActive="id" matSortDirection="asc">
                                        <ng-container matColumnDef="id">
                                          <th mat-header-cell *matHeaderCellDef>
                                            <span class="headletterspace"> ID </span>
                                          </th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.id }}
                                          </td>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                          <th mat-header-cell *matHeaderCellDef>
                                            <span class="headletterspace">Name</span>
                                          </th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                          </td>
                                        </ng-container>

                                        <ng-container matColumnDef="code">
                                          <th mat-header-cell *matHeaderCellDef>
                                            <span class="headletterspace">Code</span>
                                          </th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.code }}
                                          </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="questionCountryColumn; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: questionCountryColumn"
                                          class="assessmentrow">
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <ng-template #noCountries>
                                  <p class="text-center p-3">No countries</p>
                                </ng-template>
                              </div>
                              <div *ngIf="isToggleChecked">
                                <p class="text-center p-3">All countries</p>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>

              </div>
            </div>
          </div>

          <!-- Question country -->
          <div *ngIf="isCountryType">
            <div class="row mb-5">
              <div class="col-md-12">
                <mat-card class="p-0">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-card class="p-3">
                        <mat-card-title>
                          <div class="row" fxLayoutAlign="start center">
                            <div class="col-md-6">
                              <h3 class="pt-1 pb-1">Do not show following countries in the list</h3>
                            </div>
                            <div class="col-md-6">
                              <div style="cursor: pointer" (click)="addQuestionCountry()">
                                <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25"
                                  class="ml-1" />
                                <span class="btn-text pl-2">Add Countries</span>
                              </div>
                            </div>
                          </div>
                        </mat-card-title>
                        <mat-card-content>

                          <div *ngIf="blacklistDataLength !== 0 ; else noCountries">
                            <div class="row">
                              <div class="col-md-12">
                                <table mat-table [dataSource]="questionCountryBlacklistData" matSort
                                  class="mat-elevation-z0" matSortActive="id" matSortDirection="asc">
                                  <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef>
                                      <span class="headletterspace"> ID </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                      {{ element.id }}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>
                                      <span class="headletterspace">Name</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                      {{ element.name }}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef>
                                      <span class="headletterspace">Code</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                      {{ element.code }}
                                    </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="questionCountryColumn; sticky: true"></tr>
                                  <tr mat-row *matRowDef="let row; columns: questionCountryColumn"
                                    class="assessmentrow">
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <ng-template #noCountries>
                            <p class="text-center p-3">No countries</p>
                          </ng-template>

                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <mat-card class="p-3">
                          <mat-card-title>
                            <div class="row" fxLayoutAlign="start center">
                              <div class="col-md-6">
                                <h3 class="pt-1 pb-1">Show only following countries in the list</h3>
                              </div>
                              <div class="col-md-6">
                                <div style="cursor: pointer" (click)="removeQuestionCountry()">
                                  <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25"
                                    class="ml-1" />
                                  <span class="btn-text pl-2">Add Countries</span>
                                </div>
                              </div>
                            </div>
                          </mat-card-title>
                          <mat-card-content>
                            <div *ngIf="whitelistDataLength !== 0 ; else noCountries">
                              <div class="row">
                                <div class="col-md-12">
                                  <table mat-table [dataSource]="questionCountryWhitelistData" matSort
                                    class="mat-elevation-z0" matSortActive="id" matSortDirection="asc">
                                    <ng-container matColumnDef="id">
                                      <th mat-header-cell *matHeaderCellDef>
                                        <span class="headletterspace"> ID </span>
                                      </th>
                                      <td mat-cell *matCellDef="let element">
                                        {{ element.id }}
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                      <th mat-header-cell *matHeaderCellDef>
                                        <span class="headletterspace">Name</span>
                                      </th>
                                      <td mat-cell *matCellDef="let element">
                                        {{ element.name }}
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="code">
                                      <th mat-header-cell *matHeaderCellDef>
                                        <span class="headletterspace">Code</span>
                                      </th>
                                      <td mat-cell *matCellDef="let element">
                                        {{ element.code }}
                                      </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="questionCountryColumn; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: questionCountryColumn"
                                      class="assessmentrow">
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <ng-template #noCountries>
                              <p class="text-center p-3">No countries</p>
                            </ng-template>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Add option -->
          <div *ngIf="isChoiceType">
            <div class="row mb-5">
              <div class="col-md-12">
                <div class="text-card">
                  <h2 class="text-header">Possible Answers</h2>
                  <div style="padding: 32px; cursor: pointer" (click)="addOptionDialog()">
                    <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25" class="ml-1" />
                    <span class="btn-text pl-2">Add Option</span>
                  </div>
                </div>
                <mat-card class="pb-5">
                  <div *ngIf="questionOptionDataLength !== 0; else noOptions">
                    <mat-card-content>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="sub-table">
                            <table mat-table [dataSource]="questionOptionData" matSort class="mat-elevation-z0"
                              matSortActive="id" matSortDirection="asc">
                              <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef>
                                  <span class="headletterspace"> ID </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                  {{ element.id }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>
                                  <span class="headletterspace">Position</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                  {{ element.order }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="answerkey">
                                <th mat-header-cell *matHeaderCellDef>
                                  <span class="headletterspace">Answer Key</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                  {{ element.text }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="parents">
                                <th mat-header-cell *matHeaderCellDef>
                                  <span class="headletterspace">Parents</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                  {{ element.parents }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                  <span class="headletterspace">Actions</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                  <button type="button" mat-icon-button [matMenuTriggerFor]="optionMenu">
                                    <mat-icon>more_vert</mat-icon>
                                  </button>
                                  <mat-menu #optionMenu="matMenu">
                                    <button type="button" mat-menu-item>
                                      <span>Translate</span>
                                    </button>
                                    <button type="button" mat-menu-item [routerLink]="['./edit-option', element.id]"
                                      routerLinkActive="is-active">
                                      <span>Edit</span>
                                    </button>
                                    <button type="button" mat-menu-item (click)="removeQuestionOption(element.id)">
                                      <span>Delete</span>
                                    </button>
                                  </mat-menu>
                                </td>
                              </ng-container>

                              <tr mat-header-row *matHeaderRowDef="questionOptionColumn; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: questionOptionColumn" class="assessmentrow">
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </div>

                  <ng-template #noOptions>
                    <p class="text-center p-3">No options</p>
                  </ng-template>

                </mat-card>
              </div>
            </div>
          </div>

          <!-- Select question -->
          <div class="row">
            <div class="col-md-12">
              <div class="text-card">
                <h2 class="text-header">Parent Question</h2>
                <div style="padding: 32px; cursor: pointer" (click)="selectQuestion()">
                  <img src="assets/images/add_circle.svg" alt="Add Icon" length="25" width="25" class="ml-1" />
                  <span class="btn-text pl-2">Select Question</span>
                </div>
              </div>

              <div *ngIf="noParentQuestion !== null; else noParent">
                <div class="parent-type">
                  <!-- Question basic info -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="parent-bg">
                        <div class="text-card">
                          <h2 class="subtype-header">{{ parentID }} {{ parentText }}</h2>
                          <button type="button" mat-raised-button class="btnremove submitbtn"
                            (click)="removeDependency()">
                            Clear dependency
                          </button>
                        </div>
                        <h2 class="subtype-header">Type:<span class="type-header">&nbsp; {{ (noParentQuestion?.type ||
                            '') | titlecase }}</span></h2>

                        <mat-card>
                          <div class="row main-card">
                            <div class="col-md-12">

                              <!-- Group-->
                              <div fxLayout="row">
                                <mat-form-field fxFlex="100">
                                  <label>Parent rule type</label>
                                  <input type="text" matInput formControlName="parent_Type" class="textInput"
                                    [matAutocomplete]="parentType" #trigger="matAutocompleteTrigger"
                                    [errorStateMatcher]="matcher" />
                                  <mat-icon (click)="
                                    trigger.panelOpen
                                      ? trigger.closePanel()
                                      : trigger.openPanel()
                                    " class="custom-icon custom-form-field-icon">
                                    expand_more
                                  </mat-icon>
                                  <mat-autocomplete autoActiveFirstOption #parentType="matAutocomplete">
                                    <mat-option *ngFor="let type of filteredParentType | async" [value]="type">
                                      {{ type }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>

                              <!-- Question key -->
                              <div fxLayout="row">
                                <mat-form-field fxFlex="100">
                                  <label>Display rule</label>
                                  <input matInput formControlName="parent_Rule" class="textInput"  />
                                </mat-form-field>
                              </div>

                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #noParent>
                <p class="text-center p-5">No parent questions</p>
              </ng-template>

            </div>
          </div>
        </div>
      </div>

      <!-- Footer content -->
      <div class="footer-btn">
        <div class="row bottom-button">
          <div class="buttom-right">
            <button type="button" mat-raised-button class="btnsave backbtn" (click)="exitGroup()">
              Cancel
            </button>
            <button mat-raised-button type="submit" class="btnsave submitbtn" [disabled]="!editQuestionForm.valid">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>