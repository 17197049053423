import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CountriesService } from 'src/app/modules/countries/services/countries.service';
import { QuestionOptionService } from '../../../services/question-option.service';

@Component({
  selector: 'app-select-countries',
  templateUrl: './select-countries.component.html',
  styleUrls: ['./select-countries.component.scss']
})
export class SelectCountriesComponent implements OnInit {

  listCountries: MatTableDataSource<any> = null;
  countryID: any = [];

  countriesColumn: string[] = ['id', 'name', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private questionOption: QuestionOptionService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SelectCountriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.questionOption.getCountriesByHost().subscribe((res) => {
      console.log('List of host countries', res.data);
      this.listCountries = new MatTableDataSource<any>(res.data);
      this.listCountries.paginator = this.paginator;
      this.listCountries.sort = this.sort;

      console.log('List of country ids', this.countryID);
    });

  }

  addCountry(id: number) {
    this.countryID.push({
      "ids": [
        {
          "id": id
        }
      ]
    });
    this.questionOption.updateOptionCountry(this.data.id, this.countryID[0]).subscribe((res) => {
      console.log('Country added successfully', res);
      this.openSnackbarSuccessMessage('Country added successfully');
    });
  }

  removeCountry(countryCode: string) {
    this.questionOption.removeOptionCountry(this.data.id, countryCode).subscribe((res) => {
      console.log('Country removed successfully', res);
      this.openSnackbarSuccessMessage('Country removed successfully');
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
