import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { QuestionGroups } from '../../models/questionGroups';
import { QuestionGroupsService } from '../../services/question-groups.service';
import { QuestionService } from '../../services/question.service';
import { TranslateQuestionGroupComponent } from '../translate-question-group/translate-question-group.component';


@Component({
  selector: 'app-question-group',
  templateUrl: './question-group.component.html',
  styleUrls: ['./question-group.component.scss']
})
export class QuestionGroupComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'type', 'actions'];
  questionGroups: MatTableDataSource<QuestionGroups> = null;
  questionGroupInitialData: any = [];
  tableGroupFrom: FormGroup;
  questionTableGroups: any = [];
  newlyAddedData: any = [];
  id = '';
  name = '';
  group = '';
  is_Enabled = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private questionGroupService: QuestionGroupsService,
    private app: AppService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {

    this.questionTableGroups = [];
    this.newlyAddedData = [];

    try {
      this.getAllQuestionGroup();
    } catch (ex) { }
  }

  getAllQuestionGroup() {
    this.app.showLoader = true;
    this.questionGroupService.getQuestionGroups().subscribe((res: any) => {
      this.app.showLoader = false;
      console.log('Get all quetion groups', res.data);
      this.questionGroups = new MatTableDataSource<QuestionGroups>(res.data);
      this.questionGroups.paginator = this.paginator;
      this.questionGroups.sort = this.sort;
    });
  }

  createGroup() {
    this.router.navigate(['create-group'], { relativeTo: this.route });
  }

  enableQuestion(id: any, data: any) {
    this.app.showLoader = true;
    this.questionGroupService.enableQuestionGroupById(id, data).subscribe((res: any) => {
      this.app.showLoader = false;
      this.getAllQuestionGroup();
    });
  }

  disableQuestion(id) {
    this.app.showLoader = true;
    this.questionGroupService.disableQuestionGroupById(id).subscribe((res) => {
      this.app.showLoader = false;
      this.getAllQuestionGroup();
    });
  }

  translateQuestion(questionID) {
    const dialogRef = this.dialog.open(TranslateQuestionGroupComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: questionID
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
