<mat-sidenav-container class="example-container" autosize>
    <mat-sidenav #sidenav="matSidenav" class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
        (mouseleave)="mouseleave()">

        <mat-nav-list>
            <ng-container *ngFor="let menu of menuList | async; index as i;">
                <ng-container [ngTemplateOutlet]="menu.children ?  childMenu : parentMenu"
                    [ngTemplateOutletContext]="{menu:menu}">
                </ng-container>
            </ng-container>
        </mat-nav-list>

        <ng-template #parentMenu let-menu="menu">
            <mat-list-item [routerLink]="menu.routerLink" routerLinkActive="is-active"
                [routerLinkActiveOptions]="{exact:false}">
                {{menu.text}}
            </mat-list-item>
        </ng-template>

        <ng-template #childMenu let-menu="menu">
            <mat-expansion-panel [class.mat-elevation-z0]="true">
                <mat-expansion-panel-header>
                    {{menu.text}}
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <mat-list-item [routerLink]="submenu.routerLink" *ngFor="let submenu of menu.children"
                        routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:false}">
                        {{submenu.text}}
                    </mat-list-item>
                </mat-nav-list>
            </mat-expansion-panel>
        </ng-template>

    </mat-sidenav>

    <mat-sidenav-content>
        <div>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>