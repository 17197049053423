import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionGroups } from 'src/app/modules/questions/models/questionGroups';
import { QuestionService } from 'src/app/modules/questions/services/question.service';
import { DocumentsService } from '../../services/documents.service';
import { TranslateDocumentTypeComponent } from '../translate-document-type/translate-document-type.component';

@Component({
  selector: 'app-documents-types',
  templateUrl: './documents-types.component.html',
  styleUrls: ['./documents-types.component.scss']
})
export class DocumentsTypesComponent implements OnInit {

  documentsTypesColumn: string[] = ['id', 'key', 'name', 'trips addition', 'service', 'actions'];
  documentTypesData: MatTableDataSource<any> = null;
  questionGroupInitialData: any = [];
  tableGroupFrom: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private documentsService: DocumentsService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {

    try {
      this.getDocumentsTypes();
    } catch (ex) { }
  }

  getDocumentsTypes() {
    this.documentsService.getDocumentstypes().subscribe((res: any) => {
      console.log('Get documents type data', res.data);
      this.documentTypesData = new MatTableDataSource<any>(res.data);
      this.documentTypesData.paginator = this.paginator;
      this.documentTypesData.sort = this.sort;
    });
  }

  createDocumentType() {
    this.router.navigate(['create-document-type'], { relativeTo: this.route });
  }

  translateQuestion(doctypeID) {
    const dialogRef = this.dialog.open(TranslateDocumentTypeComponent, {
      width: '849px',
      disableClose: true,
      autoFocus: false,
      data: doctypeID
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  enableDocumentType(documentTypeId: number) {
    this.documentsService.enableDocumentType(documentTypeId).subscribe((res: any) => {
      this.openSnackbarSuccessMessage('Dcoument enabled successfully');
      this.getDocumentsTypes();
    });
  }

  disableDocumentType(documentTypeId: number) {
    this.documentsService.enableDocumentType(documentTypeId).subscribe((res: any) => {
      this.openSnackbarSuccessMessage('Dcoument disabled successfully');
      this.getDocumentsTypes();
    });
  }

  removeDocumentType(documentTypeId: number) {
    this.documentsService.removeDocumentType(documentTypeId).subscribe((res: any) => {
      this.openSnackbarSuccessMessage('Document removed successfully');
      this.getDocumentsTypes();
    });
  }

  openSnackbarSuccessMessage(msg: string) {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

}
